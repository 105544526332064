import { useLocation } from 'react-router-dom';

import { ROUTES } from '@/routes/routes';

const READ_ROUTE: string[] = [
  ROUTES.APPROVALS,
  ROUTES.PROJECTS_COLLABORATIONS,
  ROUTES.RELEASES,
  ROUTES.RELEASES_SONGS
];

export const useSongPermission = () => {
  const { pathname } = useLocation();

  const hasReadPermission = READ_ROUTE.includes(pathname);

  return {
    hasReadPermission
  };
};
