import { useRef } from 'react';
import { Row } from '@tanstack/react-table';
import { CollaborationsSongProps, ISong } from '@/models/Song.ts';
import { COLLABORATOR_STATUS } from '@/constants/song.ts';
import { useSongStore } from '@/store';

export const useReleasesTableRowHandler = () => {
  const closeDelayDrawer = useRef<NodeJS.Timeout>();
  const handleUnselectRow = useRef<() => void>();
  const { setSongData, setIsSongEditable } = useSongStore();

  return (data?: Row<ISong | CollaborationsSongProps> | undefined, onUnselectRow?: () => void) => {
    if (
      (data?.original as CollaborationsSongProps)?.collaboration_status ===
      COLLABORATOR_STATUS.pending
    ) {
      return;
    }

    handleUnselectRow.current = onUnselectRow;

    setIsSongEditable(data?.original.status !== 'release_scheduled');

    if (!data) {
      // Added setTimeout to delay the data during the animation, if you remove it,
      // then when the drawer is closed, the data in the drawer will disappear immediately and then the drawer will close.
      closeDelayDrawer.current = setTimeout(() => {
        setSongData({} as ISong);
      }, 200);
    } else {
      setSongData(data.original);
    }

    clearTimeout(closeDelayDrawer.current);
  };
};
