import { useEffect } from 'react';
import { SortOptionsProps } from '@/models/common.ts';
import { useSongStore } from '@/store';
import { useGetSongs, useInfiniteScroll } from '@/hooks';
import { Table, TableSortData } from '@/components';
import { RELEASED_SONGS_COLUMN } from './columns.tsx';
import { useReleasesTableRowHandler } from '@/pages/Releases/hooks/useReleasesTableRowHandler.ts';

const ReleasesSongs = () => {
  const handleRowClick = useReleasesTableRowHandler();
  const { sortOptions, setSortOptions } = useSongStore();
  const { infinityRef: ref, isIntersecting: inView } = useInfiniteScroll();
  const {
    data: songs = { pages: [] },
    hasNextPage,
    fetchNextPage,
    isFetching
  } = useGetSongs({ ...sortOptions, status: 'released' });

  useEffect(() => {
    if (inView && hasNextPage) {
      void fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, hasNextPage]);

  const songsList = songs.pages.flat();

  const handleSort = (options: SortOptionsProps) => {
    setSortOptions(options);
  };

  const sortData: TableSortData = {
    onSort: handleSort,
    sortOptions
  };

  return (
    <Table
      columns={RELEASED_SONGS_COLUMN}
      data={songsList}
      sortData={sortData}
      onRowClick={handleRowClick}
      infinityRef={ref}
      isLoading={isFetching}
    />
  );
};

export default ReleasesSongs;
