import { MouseEvent } from 'react';
import { ItemMenu, MenuOptionProps } from '@/components';
import { ISong } from '@/models/Song.ts';
import { useMantineTheme } from '@mantine/core';
// import { useModalStore } from '@/store';
// import { AGREED_TERMS_UPLOAD_TRACK_MODAL_NAME, DELETE_SONG_MODAL_NAME } from '@/constants/song';

interface ItemMenuCellProps {
  data: ISong;
}

export const ItemMenuCell = (props: ItemMenuCellProps) => {
  const { data } = props;

  // const { openModal } = useModalStore();
  const { colors } = useMantineTheme();

  const buttonProps = {
    'data-cell-hover': 'show',
    onClick: (event: MouseEvent<HTMLButtonElement>) => event.stopPropagation()
  };

  const handleEditSongDetails = (event: unknown) => {
    (event as MouseEvent<HTMLButtonElement>).stopPropagation();

    //console.log('Edit Song Details');
    // openModal({ name: DELETE_SONG_MODAL_NAME, songId: data.id });
  };

  const handleRevokeRelease = () => {
    //console.log('Revoke Release');
    // openModal({
    //   name: AGREED_TERMS_UPLOAD_TRACK_MODAL_NAME,
    //   voiceId: data.id,
    //   title: data.name,
    //   artistName: data.display_artist,
    //   split: `${data.voices[0].royalty_percent}`
    // });
  };

  const options: MenuOptionProps[] = [
    {
      label: 'Edit song details',
      onClick: (event: unknown) => handleEditSongDetails(event)
    }
  ];

  if (data.master_url) {
    options.push({
      label: 'Revoke release',
      color: colors.red[0],
      onClick: handleRevokeRelease
    });
  }

  return <ItemMenu options={options} buttonProps={buttonProps} />;
};
