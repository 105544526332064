import React from 'react';

import { ButtonLib } from '@/components';
import { ISong, SingleSongStatus } from '@/models/Song';
import { AGREED_TERMS_UPLOAD_TRACK_MODAL_NAME, STATUS } from '@/constants/song';
import { ButtonBackgroundProps, ButtonVariantProps } from '@/components';
import { useModalStore, useSongStore } from '@/store';
import { useSongPermission } from '@/hooks';

import styles from './song-status-button.module.css';
import { RELEASE_MODAL_NAME } from '@/pages/Projects';

interface StatusButtonProps {
  data: ISong;
  size?: 'xs' | 'lg';
}

const STATUS_COLOR: { [k in SingleSongStatus]: ButtonBackgroundProps } = {
  draft: 'secondary',
  denied: 'fourth',
  upload_for_approval: 'fourth',
  awaiting_approval: 'secondary',
  change_artist: 'primary',
  released: 'third',
  release_song: 'third',
  release_scheduled: 'secondary',
  taken_down: 'secondary',
  archived: 'secondary'
};

const STATUS_VARIANT: { [k in SingleSongStatus]: ButtonVariantProps } = {
  draft: 'outline',
  denied: 'filled',
  upload_for_approval: 'filled',
  awaiting_approval: 'outline',
  change_artist: 'filled',
  released: 'filled',
  release_song: 'filled',
  release_scheduled: 'outline',
  taken_down: 'outline',
  archived: 'outline'
};

const BTN_STATUS = ['upload_for_approval', 'release_song'];

export const SongStatusButton = (props: StatusButtonProps) => {
  const { size = 'xs' } = props;
  const { status, id, name, display_artist, voices } = props.data;

  const { setSongData, isSongEditable } = useSongStore();
  const { openModal } = useModalStore();
  const { hasReadPermission } = useSongPermission();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (status === 'release_song') {
      setSongData(props.data);

      // TODO: This feature is hidden for MVP, delete stub modal and change name value to RELEASE_MODAL_NAME when it is ready
      openModal({ name: RELEASE_MODAL_NAME });

      return;
    }

    if (status === 'upload_for_approval') {
      openModal({
        name: AGREED_TERMS_UPLOAD_TRACK_MODAL_NAME,
        voiceId: id,
        title: name,
        artistName: display_artist,
        split: `${voices[0]?.royalty_percent}`,
        voiceAI: voices[0]?.name
      });

      return;
    }
  };

  const background = STATUS_COLOR[status];
  const variant = STATUS_VARIANT[status];

  if (BTN_STATUS.includes(status)) {
    return (
      <ButtonLib
        onClick={handleClick}
        background={background}
        variant={variant}
        size={size}
        disabled={hasReadPermission || !isSongEditable}
      >
        {STATUS[status]}
      </ButtonLib>
    );
  }

  return (
    <div className={`${styles.base} ${styles[size]} ${styles[variant]}`}>{STATUS[status]}</div>
  );
};
