import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';

import { ISong } from '@/models/Song';
import { PlaySongCell } from '@/components';
import { DEFAULT_DATE_FORMAT } from '@/constants';

import { ItemMenuCell } from './components/ItemMenuCell';

const columnHelper = createColumnHelper<ISong>();

export const RELEASED_SONGS_COLUMN = [
  columnHelper.accessor('name', {
    header: 'TITLE',
    cell: (info) => {
      const { original } = info.row;

      const data = {
        image_url: original.image_url,
        title: original.name,
        subtitle: original.subtitle,
        master_url: original.master_url,
        id: original.id
      };

      return <PlaySongCell data={data} />;
    },
    size: 35
  }),
  columnHelper.accessor('display_artist', {
    header: 'AI VOCAL',
    cell: (info) => (
      <div className="text-hookybase-200 dark:text-hookybase-300">{info.getValue()}</div>
    ),
    size: 27
  }),
  columnHelper.accessor('release_date', {
    header: 'DATE',
    cell: (info) => (
      <div className="text-hookybase-200 dark:text-hookybase-300">
        {dayjs(info.getValue()).format(DEFAULT_DATE_FORMAT)}
      </div>
    ),
    size: 8
  }),
  columnHelper.accessor('genre_id', {
    header: 'GENRE',
    cell: (info) => (
      <div className="text-hookybase-200 dark:text-hookybase-300">
        {info.getValue().slice(0, 8)}
      </div>
    ),
    size: 10
  }),
  columnHelper.accessor('id', {
    header: ' ',
    cell: (info) => <ItemMenuCell data={info.row.original} />,
    size: 1
  })
];

export const RELEASED_COLLABORATIONS_COLUMN = [
  columnHelper.accessor('name', {
    header: 'TITLE',
    cell: (info) => {
      const { original } = info.row;

      const data = {
        image_url: original.image_url,
        title: original.name,
        subtitle: original.subtitle,
        master_url: original.master_url,
        id: original.id
      };

      return <PlaySongCell data={data} />;
    },
    size: 35
  }),
  columnHelper.accessor('display_artist', {
    header: 'AI VOCAL',
    cell: (info) => (
      <div className="text-hookybase-200 dark:text-hookybase-300">{info.getValue()}</div>
    ),
    size: 17
  }),
  columnHelper.accessor('release_date', {
    header: 'DATE',
    cell: (info) => (
      <div className="text-hookybase-200 dark:text-hookybase-300">
        {dayjs(info.getValue()).format(DEFAULT_DATE_FORMAT)}
      </div>
    ),
    size: 8
  }),
  columnHelper.accessor('genre_id', {
    header: 'GENRE',
    cell: (info) => (
      <div className="text-hookybase-200 dark:text-hookybase-300">{info.getValue()}</div>
    ),
    size: 7
  }),
  columnHelper.accessor('id', {
    header: ' ',
    cell: (info) => <ItemMenuCell data={info.row.original} />,
    size: 1
  })
];
