import { SVGAttributes } from 'react';

const TidalIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.9304 7.7319L11.948 11.7151L7.96484 7.7319L11.948 3.75041L15.9304 7.7319Z"
        fill="white"
      />
      <path
        d="M15.9304 15.6977L11.948 19.6809L7.96484 15.6977L11.948 11.7145L15.9304 15.6977Z"
        fill="white"
      />
      <path
        d="M7.96552 7.73264L3.98234 11.7158L0 7.73264L3.98234 3.7503L7.96552 7.73264Z"
        fill="white"
      />
      <path
        d="M23.8952 7.73264L19.9129 11.7158L15.9297 7.73264L19.9129 3.7503L23.8952 7.73264Z"
        fill="white"
      />
    </svg>
  );
};

TidalIcon.displayName = 'TidalIcon';

export default TidalIcon;
